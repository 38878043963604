import React, { useState } from 'react';
import emailjs from 'emailjs-com';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';

export default function ContactPage() {
  const [messageText, setMessageTxt] = useState(null);
  const [messageType, setMessageType] = useState('success');

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_aw3f9ss', 'template_emc99ow', e.target, 'user_CVfdancMK4gqxAaHyX224')
      .then((result) => {
        setMessageTxt(result.text);
        setMessageType('success');
      }, (error) => {
        setMessageTxt(error.text);
        setMessageType('error');
      });
  }

  return (
    <Box py={4}>
      <Container maxWidth="md">
        <Typography align="center" variant="h4" component="h1" gutterBottom>
          Contact Us.
        </Typography>
        <Box pt={3}>
          <Grid container spacing={5}>
            <Grid item xs={12} md={6}>
              <form noValidate autoComplete="off" onSubmit={sendEmail}>
                <Box pb={3}>
                  <TextField
                    id="yourName" label="Your Name" variant="filled"
                    style={{ width: '100%' }} required name="yourName"
                  />
                </Box>
                <Box pb={3}>
                  <TextField
                    id="yourEmail" label="Your Email" variant="filled"
                    style={{ width: '100%' }} required name="yourEmail"
                  />
                </Box>
                <Box pb={3}>
                  <TextField
                    id="emailSubject" label="Subject" variant="filled"
                    style={{ width: '100%' }} required name="emailSubject"
                  />
                </Box>
                <Box pb={3}>
                  <TextField
                    id="yourFeedback" label="Share your thoughts" variant="filled" required
                    multiline rowsMax={4} rows={4} style={{ width: '100%' }} name="yourFeedback"
                  />
                </Box>
                {messageText && 
                  <Box pb={3}>
                    <Alert variant="outlined" severity={messageType}>{messageText}</Alert>
                  </Box>
                }
                <Button type="submit" variant="contained" color="primary">Share Your Feedback</Button>
              </form>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography align="left" variant="h6" component="p" gutterBottom>Do you have any ideas, improvements or suggestions you'd like to share?  We'd love to hear it!  Discord is the place to get a rapid response, but you are also welcome to send in your comments via this method.  Responses are not guaranteed.  Thanks!</Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}
