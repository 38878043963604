import { createTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#90caf9'
    },
    warning: {
      main: '#ffa726'
    },
    success: {
      main: '#66bb6a'
    },
    error: {
      main: '#f44336'
    },
  },
});

export default theme;
