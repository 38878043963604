export const datacronLevel = (level, data) => {

  let buildList = []
  data.forEach(d => {
    d.name.includes(level) && buildList.push(d)
  })
  return buildList
}

export const datacronSelected = (level, data) => {
  let buildDc = data.find(f => f.datacronAbility.name.includes(level))

  return buildDc ? buildDc.datacronAbility : null
}