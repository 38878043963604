import React from 'react';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import GABuddy_Poster from './assets/GAB_Poster_v2.png';
import GABuddy_Teaser from './assets/GAB_Teaser_Yellow.png';
import LinkList from '../../components/linkList';


const links = [
  {
    title: 'Sign up for testing here',
    description: 'Grand Arena Buddy testing signup',
    type: 'web',
    url: 'https://forms.gle/c773eXegUadVuC7t9'
  },
  {
    title: 'Join discord to ask questions and talk to experts',
    description: 'Grand Arena Science discord',
    type: 'web',
    url: 'https://discord.gg/29yYq2JT57'
  },
];

export default function GABuddyPosterPage() {

  return (
    <Box>

      <Box py={4}>
        <Container maxWidth="md">
          <Typography align="center" variant="h4" component="h2" gutterBottom>
            Grand Arena Buddy - testing is starting!
        </Typography>
          <Typography align="left" variant="h6" component="p" gutterBottom>
            Grand Arena Science is thrilled to announce that our GAC Planner Tool "GA Buddy" has reached the testing phase! We are looking for individuals willing to help with beta testing. This testing will begin on June 20th for a small group of testers. In the coming weeks we will expand the testing to a larger number of players.
          </Typography>
          <Typography align="left" variant="h6" component="p" gutterBottom>
            Testers will be able to use GA Buddy for a number of things including scouting your opponents, planning a strategy, and even getting specific defensive/offensive recommendations (among a number of other features!) Specific details as to what we are looking for from our testers will be provided when we grant you access to GA Buddy but generally we will be looking for insight on functionality and aesthetics. If you would like to be a tester, please fill out the google form in links below and we will be in touch!
          </Typography>
          <Typography align="left" variant="h6" component="p" gutterBottom>
          Thanks and see you on the holotables!
          </Typography>
        </Container>
      </Box>

      <Box py={4} bgcolor="background.paper">
        <Container maxWidth="md">
          <img src={GABuddy_Poster} alt="Grand Arena Buddy poster" />
        </Container>
      </Box>

      <Box py={4} bgcolor="background.paper">
        <Container maxWidth="md">
          <img src={GABuddy_Teaser} alt="Grand Arena Buddy teaser" />
        </Container>
      </Box>

      <Box py={4}>
        <Container maxWidth="md">
          <Typography align="center" variant="h4" component="h2" gutterBottom>
            Links.
          </Typography>
          <LinkList items={links} />
        </Container>
      </Box>

    </Box>
  );
}