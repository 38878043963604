import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

import './style.scss';

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    }
  },
  menuLinks: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    }
  },
  appBar: {
    background: 'rgba(0,0,0,.8)'
  },
  drawer: {
    width: 270
  },
  brand: {
    [theme.breakpoints.up('md')]: {
      flexGrow: 1
    }
  }
}));

const links = [
  { label: 'Home', to: '/' },
  { label: 'Counters', to: '/counters/' },
  { label: 'Datacrons', to: '/datacrons/' },
  { label: 'Resources', to: '/resources/' },
  { label: 'Coming Soon', to: '/comingsoon/' },
  { label: 'Contact Us', to: '/contact/' }
];

const Navigation = () => {
  const [drawer, setDrawer] = useState(false);
  const classes = useStyles();

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawer(open);
  };

  const MobileMenu = () =>
    <div
      className={classes.drawer}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <Box className="gas-hero gas-hero--short" />
      <List>
        {links.map((l, i) =>
          <NavLink to={l.to} key={i} className="gas-navigation__link">
            <ListItem button >
              <ListItemText primary={l.label} />
            </ListItem>
          </NavLink>
        )}
      </List>
    </div>;

  return (
    <Box className="gas-navigation">
      <AppBar position="static" className={classes.appBar} color="transparent">
        <Toolbar>
          <IconButton
            edge="start" className={classes.menuButton}
            color="inherit" aria-label="menu"
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>          
          <Typography variant="h6" className={classes.brand}>
            Grand Arena Science
          </Typography>
          <Drawer open={drawer} onClose={toggleDrawer(false)}>
            <MobileMenu />
          </Drawer>
          <Box className={classes.menuLinks}>
            {links.map((l, i) =>
              <Button
                key={i} component={NavLink}
                to={l.to} color="inherit"
              >
                {l.label}
              </Button>
            )}
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default Navigation;