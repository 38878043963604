import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import { getUnitByName, sortAtoZ } from '../../utils';
import CounterCard from './card';
import Alert from '../alert';
import CounterFilters from './filters';
import './style.scss';
import { Button } from '@material-ui/core';
import SubmitCounter from '../submit';

const useStyles = makeStyles({
  backdrop: {
    zIndex: 1000,
  }
});

const axiosConfig = {
  headers: {
    'X-API-KEY': 'ReadOnly'
  }
};

const Counters = (props) => {
  const classes = useStyles();
  const [counterResults, setResults] = useState([]);
  const [totalResults, setTotal] = useState(null);
  const [defLeaderList, setDefLeaderList] = useState([]);
  const [atkLeaderList, setAtkLeaderList] = useState([]);
  const [datacrons, setDatacrons] = useState([]);
  const [dataAbilities, setDataAbilities] = useState([]);
  const [datasets, setDatasets] = useState([]);
  const [leader, setLeader] = useState(null);
  const [squadUnits, setSquadUnits] = useState([]);
  const [toonList, setToonList] = useState([]);
  const [shipList, setShipList] = useState([]);
  const [dcThree, setDcThree] = useState([]);
  const [dcSix, setDcSix] = useState([]);
  const [dcNine, setDcNine] = useState([]);
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(true);
  const [expiredDcs, setExpiredDcs] = useState(false);
  const [searchBy, setSearchBy] = useState('def');

  useEffect(() => {
    let unitDefinitions = [];
    let defendingLeaders = [];
    let attackingLeaders = [];

    axios.get('https://gasapi.info/api/countermanagement/Definition/datacronabilities', axiosConfig)
      .then(response => {
        const abilities = response.data.datacronAbilities;
        let levelThree = abilities.filter(a => a.code.includes('datacron_alignment'));
        let levelSix = abilities.filter(a => a.code.includes('datacron_faction'));
        let levelNine = abilities.filter(a => a.code.includes('datacron_character'));
        setDataAbilities(abilities)
        setDcThree(levelThree);
        setDcSix(levelSix);
        setDcNine(levelNine);
        setOpen(false);
      })
      .catch(error => {
        setOpen(false);
        console.log(error);
      });

    axios.get('https://gasapi.info/api/countermanagement/Definition/datacronsets', axiosConfig)
      .then(response => {
        setDatasets(response.data.datacronSets);
        setOpen(false);
      })
      .catch(error => {
        setOpen(false);
        console.log(error);
      });

    axios.get('https://gasapi.info/api/countermanagement/Definition/units', axiosConfig)
      .then(response => {
        let toons = [];
        let ships = [];
        unitDefinitions = response.data.unitDefinitions;
        unitDefinitions.forEach(u => {
          u.combatType.code === 'SQUAD' ? toons.push(u) : ships.push(u);
        });
        setToonList(toons);
        setShipList(ships);

        axios.get(`https://gasapi.info/api/countermanagement/Counter/list?gameModeCode=${props.type === 'fleet' ? 'ships' : props.type}`, axiosConfig)
          .then(response => {
            const sortAtt = sortAtoZ(response.data.attackingLeaders);
            const sortDef = sortAtoZ(response.data.defendingLeaders);
            defendingLeaders = sortDef.map(l => getUnitByName(unitDefinitions, l));
            attackingLeaders = sortAtt.map(l => getUnitByName(unitDefinitions, l));
            setDefLeaderList(defendingLeaders);
            setAtkLeaderList(attackingLeaders);
            setOpen(false);
          })
          .catch(error => {
            setOpen(false);
            console.log(error);
          });
      })
      .catch(error => {
        setOpen(false);
        console.log(error);
      });

  }, [props.type]);


  const loadResults = (lead, dcs, units, skip, more, expDc) => {
    setOpen(true);
    let showExpDcs = props.type === 'fleet' ? true : expDc;
    axios.get(`https://gasapi.info/api/countermanagement/Counter?withExpiredDatacronSets=${showExpDcs}&skip=${!more ? 0 : skip}&take=18&${searchBy === 'def' ? 'defending' : 'attacking'}LeaderCode=${lead}&gameModeCode=${props.type === 'fleet' ? 'ships' : props.type}${dcs.join('')}${units.join('')}`, axiosConfig)
      .then(response => {
        !more ? setResults(response.data.counters) : setResults([...counterResults, ...response.data.counters]);
        !more && setTotal(response.data.totalCounters);
        setPage(!more ? 0 : skip);
        setOpen(false);
      })
      .catch(error => {
        setOpen(false);
        console.log(error);
      });
  }


  const chooseToon = (v) => {
    let getLeader = v.length > 0 ? v[0].code : null;
    let squadParams = [];
    v.length > 1 && v.forEach((f, i) => {
      i !== 0 && squadParams.push(`&${searchBy === 'def' ? 'defending' : 'attacking'}TeamUnitCodes=${f.code}`);
    });
    loadResults(getLeader, datacrons, squadParams, page, false, expiredDcs);
    setLeader(getLeader);
    setSquadUnits(squadParams);
    !getLeader && setDatacrons([]);
  }

  const changeDcs = (v) => {
    let dcParams = [];
    v.length > 0 && v.forEach(e => {
      dcParams.push(`&${searchBy === 'def' ? 'defending' : 'attacking'}DatacronAbilityCodes=${e}`);
    });
    loadResults(leader, dcParams, squadUnits, page, false, expiredDcs);
    setDatacrons(dcParams);
  }

  const loadMore = () => {
    let getPage = page + 18;
    loadResults(leader, datacrons, squadUnits, getPage, true, expiredDcs);
  }

  const changeSearchBy = (v) => {
    setSearchBy(v);
    if (v !== searchBy) {
      setResults([]);
      setTotal(null);
      setPage(0);
      setLeader(null);
      setDatacrons([]);
      setSquadUnits([]);
    }
  }

  const toggleExpDcs = () => {
    let toggleExp = !expiredDcs
    leader && loadResults(leader, datacrons, squadUnits, page, false, toggleExp);
    setExpiredDcs(toggleExp);
  }

  return (
    <Box>
      <CounterFilters
        type={props.type} addToons={chooseToon}
        leaders={searchBy === 'def' ? defLeaderList : atkLeaderList}
        searchBy={searchBy} changeSearchby={changeSearchBy}
        dcThree={dcThree} dcSix={dcSix} dcNine={dcNine}
        squad={props.type === 'fleet' ? shipList : toonList} showExpDcs={expiredDcs}
        changeDcs={changeDcs} leader={leader} toggleExpDcs={toggleExpDcs}
      />
      <Grid container spacing={3}>
        {leader && counterResults.length > 0 ? counterResults.map((c, i) =>
          <Grid item xs={12} sm={props.type === 'fleet' ? 10 : 6} md={props.type === 'fleet' ? 6 : 4} lg={4} xl={3} key={i}>
            <CounterCard type={props.type} data={c} />
          </Grid>
        )
          :
          !open && leader && <Grid item>
            <Alert severity="info">No counters found for the selected team. Do you have one? Why not submit using the button on the bottom right of this page.</Alert>
          </Grid>
        }
        <Backdrop open={open} className={classes.backdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Grid>
      {totalResults > 0 &&
        <Box mt={2} display='flex' flexDirection='column' alignItems='center'>
          <Box mb={1}>Showing {totalResults < page + 18 ? totalResults : page + 18} out of {totalResults}</Box>
          <Box>
            <Button variant='contained' color='primary' disabled={((totalResults < 19) || totalResults < page + 18)} onClick={loadMore}>Load More</Button>
          </Box>
        </Box>
      }
      <SubmitCounter units={toonList} ships={shipList} datacrons={dataAbilities} datasets={datasets} />
    </Box>
  );
}

export default Counters;