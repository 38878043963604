import { useState } from 'react'

import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Box } from '@material-ui/core'

const DataSet = (props) => {
  const { options, selected, onChange, type } = props
  const [inputValue, setInputValue] = useState('')

  return (
    <Box marginBottom={3} marginTop={2}>
      <Autocomplete
        value={selected}
        onChange={(e, newValue) => {
          onChange(newValue, type)
        }}
        inputValue={inputValue}
        onInputChange={(e, newInputValue) => {
          setInputValue(newInputValue)
        }}
        getOptionLabel={(option) => option ? `${option.name} (Set ${option.code})` : ''}
        id={`selectDataset${type}`}
        options={options}
        sx={{ width: '100%' }}
        renderInput={(params) => <TextField {...params} label="Set" variant="outlined" />}
        //isOptionEqualToValue={(option, value) => option.code === value.code}
      />
    </Box>
  );
}

export default DataSet;