import Grid from '@material-ui/core/Grid';

import Squad from './squad'

const SquadSelection = (props) => {
  const { attackingUnits, defendingUnits, units, onUnitChange, gameMode, ships, onUnitRemove } = props

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Squad title='Attacking' data={attackingUnits} units={gameMode === 'SHIPS' ? ships : units} onChange={onUnitChange} gameMode={gameMode} onUnitRemove={onUnitRemove} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Squad title='Defending' data={defendingUnits} units={gameMode === 'SHIPS' ? ships : units} onChange={onUnitChange} gameMode={gameMode} onUnitRemove={onUnitRemove} />
      </Grid>
    </Grid>
  );
}

export default SquadSelection;