
import { useState } from 'react'
import axios from 'axios'

import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import CounterForm from './counterForm'
import { datacronLevel } from './utils'

const axiosConfig = {
  headers: {
    'X-API-KEY': 'ReadOnly'
  }
};

const Review = (props) => {
  const { units, datacrons, setOpen, datasets, ships, setMessage } = props
  const handleClose = () => setOpen(false)
  const [attackingUnits, setAttackingUnits] = useState([])
  const [defendingUnits, setDefendingUnits] = useState([])


  const onCounterSubmit = (form, attackingSet, attackingDcs, defendingSet, defendingDcs) => {
    let getAttackingDcs = attackingDcs.length > 0 ? attackingDcs.map(a => a.datacronAbility.code) : []
    let getDefendingDcs = defendingDcs.length > 0 ? defendingDcs.map(a => a.datacronAbility.code) : []
    let getDefendingUnits = defendingUnits.length > 0 && defendingUnits.map(a => a.unitDefinition.code)
    let getAttackingUnits = attackingUnits.length > 0 && attackingUnits.map(a => a.unitDefinition.code)

    let payload = [
      {
        gameModeCode: form.gameMode,
        attackingTeamDefinitionCodes: getAttackingUnits,
        defendingTeamDefinitionCodes: getDefendingUnits,
        attackingDatacronSetCode: attackingSet?.code,
        attackingDatacronAbilityCodes: getAttackingDcs,
        defendingDatacronSetCode: defendingSet?.code,
        defendingDatacronAbilityCodes: getDefendingDcs,
        difficultyCode: form.difficulty,
        banners: Number(form.banners),
        season: Number(form.season),
        attempt: Number(form.attempt),
        videoLink: form.video,
        description: form.notes,
        languageCode: 'ENG',
        videoTypeCode: 'GAC',
        isAdminApproved: false
      }
    ]

    axios.post('https://gasapi.info/api/countermanagement/Counter', payload, axiosConfig)
    .then(response => {
      setOpen(false)
      setMessage({ text: 'Successfully submitted a new counter. Approval is required by an admin user before it becomes available.', severity: 'success'});
    })
    .catch(error => {
      setMessage({ text: 'There has been a problem submitting your counter. Please check the details have been entered correctly.', severity: 'error'});
    });
  }

  const onUnitChange = (v, sequence, type) => {
    let getState = type === 'Attacking' ? attackingUnits : defendingUnits
    let removeDc = getState.filter((c, i) => i !== sequence)
    let updateDc = [...removeDc, { order: sequence + 1, unitDefinition: v }]
    type === 'Attacking' ? setAttackingUnits(updateDc) : setDefendingUnits(updateDc)
  }

  const onUnitRemove = (sequence, type) => {
    let getState = type === 'Attacking' ? attackingUnits : defendingUnits
    let removeDc = getState.filter((c, i) => i !== sequence)
    let updateDc = [...removeDc]
    type === 'Attacking' ? setAttackingUnits(updateDc) : setDefendingUnits(updateDc)
  }

  return (
    <Dialog
      open
      onClose={handleClose}
      scroll='paper'
      maxWidth='md'
    >
      <DialogTitle id="scroll-dialog-title">Submit Counter</DialogTitle>
      <DialogContent dividers>
        <Box>
          <Typography variant='h6'>Details</Typography>
          <CounterForm
            onCounterSubmit={onCounterSubmit}
            levelThree={datacronLevel('datacron_alignment', datacrons)}
            levelSix={datacronLevel('datacron_faction', datacrons)}
            levelNine={datacronLevel('datacron_character', datacrons)}
            onCancel={handleClose} datasets={datasets}
            attackingUnits={attackingUnits} defendingUnits={defendingUnits}
            units={units} onUnitChange={onUnitChange}
            setDefendingUnits={setDefendingUnits} setAttackingUnits={setAttackingUnits}
            ships={ships} onUnitRemove={onUnitRemove}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default Review;