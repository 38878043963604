import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import SvgIcon from '@material-ui/core/SvgIcon';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import ResourcesIntro from '../components/resources/intro';
import ResourceMenu from '../components/resources/menu';
import { convertCsvToJson } from '../utils';
import './styles/home.scss';

const useStyles = makeStyles((theme) => ({
  link: {
    color: '#a6d4fa'
  },
  resLabel: {
    display: 'flex',
    flexDirection: 'column'
  },
  backdrop: {
    zIndex: 1000,
  }
}));

const HomePage = () => {
  const [newsData, setNews] = useState([]);
  const [open, setOpen] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await axios.get('https://docs.google.com/spreadsheets/d/e/2PACX-1vTrovsrbEs4mJiln9YiW4AnSlBSmV1VQk9HgTFyDmxIGVjrT7pKG_OPHcYwzVkRyN1zc7UgqMluxXTO/pub?gid=0&single=true&output=csv');
      let convertData = convertCsvToJson(data, ',');
      setNews(convertData);
      setOpen(false);
    };

    fetchData();
  }, []);

  const classes = useStyles();

  return (
    <Box>
      <Box py={4} bgcolor="background.paper">
        <Container maxWidth="md">
          <Typography align="center" variant="h4" component="h2" gutterBottom>
            Welcome to Grand Arena Science!
            </Typography>
          <Typography align="left" variant="h6" component="p" gutterBottom>
            We're a community and resource center for the Grand Arena game mode within Star Wars: Galaxy of Heroes.  Our goal is to provide detailed and thoroughly researched data, guides and SCIENCE which will help you succeed, excel and even dominate in Grand Arena. If you have any feedback, questions or would like to make a suggestion, please join us on <Link className={classes.link} rel="noopener" href="https://discord.gg/29yYq2JT57" target="_blank">Discord</Link>.  We also occasionally add content on our <Link className={classes.link} color="secondary" rel="noopener" href="https://www.youtube.com/channel/UCcw57dMcM6PBE6XNdYZTxOg" target="_blank">YouTube</Link> channel if you would like to take a look.  Thanks for visiting!
            </Typography>
        </Container>
      </Box>

      <Box py={4} bgcolor="primary.dark">
        <Container maxWidth="md">
          <Box className="news">
            {newsData.length > 0 && newsData.map((n, i) =>
              <Box className="news__item" key={i} onClick={() => window.open(n.Link)}>
                <Box className="news-date">
                  <Box className="news-date__icon">
                    <Box className="news-date__date">
                      <Typography className="news-date__day">{n.Day}</Typography>
                      <Typography className="news-date__month">{n.Month}</Typography>
                    </Box>
                  </Box>
                </Box>
                <Box className="news__content">{n.Content}</Box>
                <Box className="news__link">                  
                  <SvgIcon>
                    <ArrowForwardIosIcon />
                  </SvgIcon>
                </Box>
              </Box>
            )}
          </Box>
        </Container>
      </Box>

      <Box py={4}>
        <Container maxWidth="md">
          <Typography align="center" variant="h4" component="h2" gutterBottom>
            Counters.
          </Typography>
          <Typography align="left" variant="h6" component="p" gutterBottom>
            The searchable database of Grand Arena counters, all individually curated by GAC experts.  The vast majority are from actual GAC matches and every video has been individually selected as a good representation of the match (either winning or losing). Start by selecting the format, selecting defense OR offense sorting and then entering the character(s) on the team you wish to use or counter by clicking on the +.  You can also submit a counter of your own by choosing the "+ Submit Counter" button in the bottom right.
          </Typography>
        </Container>
        <Container maxWidth="xl">
          <Box py={4}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <Card>
                  <CardActionArea component={NavLink} to="/counters/">
                    <CardMedia component="img" image="/images/5v5-card.jpg" />
                    <CardContent>
                      <Typography align="center" variant="h5" component="h2">5v5</Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Card>
                  <CardActionArea component={NavLink} to="/counters/3v3/">
                    <CardMedia component="img" image="/images/3v3-card.jpg" />
                    <CardContent>
                      <Typography align="center" variant="h5" component="h2">3v3</Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Card>
                  <CardActionArea component={NavLink} to="/counters/fleet/">
                    <CardMedia component="img" image="/images/fleet-card.jpg" />
                    <CardContent>
                      <Typography align="center" variant="h5" component="h2">Fleet</Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>

      <Box py={4} bgcolor="background.paper">
        <ResourcesIntro />
        <ResourceMenu />
      </Box>

      <Backdrop open={open} className={classes.backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>

    </Box>
  );
}

export default HomePage;