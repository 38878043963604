import React, { useState } from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

import DamageMod from '../unit/mod';
import ChangeLeader from './changeLeader';
import ChangeAdditional from './changeAdditional';

const useStyles = makeStyles({
  cancelBtn: {
    marginLeft: 8
  },
  addBtn: {
    margin: '0 auto',
    display: 'block'
  }
});

const DamageSim = (props) => {
  const classes = useStyles();
  const [showSim, setShowSim] = useState(false);
  const [mods, setMods] = useState(props.mods);
  const [leader, setLeader] = useState('0');
  const [modifiers, setModifiers] = useState([]);

  const onChangeMod = (mod) => {
    let updatedMods = mods.map((m) => m.slot === mod.slot ? mod : m);
    setMods(updatedMods);
  }

  const onSubmit = () => {
    props.onSubmit && props.onSubmit(mods, leader, modifiers, props.id);
  }

  return (
    <div>
      <div className="gas-dmg-unit">
        <Box px={2} pb={2}>
          <Typography align="center" variant="h5" component="h2">
            Simulation {props.id}
          </Typography>
          {(showSim || props.showEdit) &&
            <>
              {mods &&
                <div className="gas-dmg-unit__mods">
                  {mods.map((m, i) =>
                    <DamageMod data={m} key={i} changeMod={onChangeMod} />
                  )}
                </div>
              }
              <ChangeLeader onChange={(v) => setLeader(v)} />
              <ChangeAdditional onChange={(v) => setModifiers(v)} isJedi={props.name.categoryIdList.includes('profession_jedi')} />
            </>
          }
        </Box>
      </div>
      <div className="gas-dmg-results">
        <Box pb={1}>
          {(showSim || props.showEdit) ?
            <>
              <Button variant="contained" color="primary" onClick={onSubmit}>Simulate</Button>
              <Button
                classes={{ root: classes.cancelBtn }}
                onClick={() => setShowSim(false)}
              >
                Cancel
              </Button>
            </>
          :
            <Button 
              variant="contained" color="primary"
              onClick={() => setShowSim(true)}
              classes={{ root: classes.addBtn }}
            >
              Add Simulation
            </Button>
          }
        </Box>
      </div>
    </div>
  );
}

export default DamageSim;