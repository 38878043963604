import { readString } from 'react-papaparse';

export const convertCsvToJson = (str) => {
    const results = readString(str, { header: true });
    return results.data;
};

export const counterApi = (type) => {
    let gid = 847649831;
    if(type === '3v3') {
        gid = 694836848;
    } else if(type === 'fleet') {
        gid = 1397959394;
    }
    return `https://docs.google.com/spreadsheets/d/e/2PACX-1vR02nOcoyFdc7zEdRJ4U_YFQtkftUukneiJ1xFPxzdOdQgewvaBvMgwTK8ytotxPxWQEMwGIkHCj_kI/pub?gid=${gid}&single=true&output=csv`;
}

export const datacronApi = (type) => {
    let gid = 847649831;
    if(type === '3v3') {
        gid = 518472159;
    } else {
        gid = 1885542060;
    }
    return `https://docs.google.com/spreadsheets/d/e/2PACX-1vSs1wNk_nY13zgYTTZlcC3RISdtnu7jg0jJjkpyT_6D61h5wR7IpE-khIR_teavMJwTxRY6NquUMASj/pub?gid=${gid}&single=true&output=csv`;
}