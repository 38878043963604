import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import Unit from './unit'

const Squad = (props) => {
  const { data, units, title, onChange, gameMode, onUnitRemove } = props

  return (
    <Box marginBottom={3}>
      <Typography variant='h6'>{title} {gameMode === 'SHIPS' ? 'Fleet' : 'Squad'}</Typography>
      <Box display='flex' marginTop={1}>
        {data?.map((d, i) =>
          <Unit options={units} selected={d.unitDefinition} key={d.unitDefinition.code} onRemove={onUnitRemove} placement={i} type={title} onChange={onChange} />
        )}
        {((gameMode === '5V5' && data.length < 5) || (gameMode === '3V3' && data.length < 3) || (gameMode === 'SHIPS' && data.length < 8)) && <Unit options={units} type={title} onChange={onChange} />}
      </Box>
    </Box>
  );
}

export default Squad;