import React from 'react';

import Typography from '@material-ui/core/Typography';
import StarIcon from '@material-ui/icons/Star';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';

import DamageMod from '../unit/mod';
import DamageStats from './stats';
import { getCharacter } from '../../../utils';

const DamageUnit = (props) => {

  return (
    <div className="gas-dmg-unit">

      <Box style={{ position: 'relative', height: 40 }}>
        <Typography style={{ paddingRight: props.isSim ? 24 : 0 }} align="center" variant="h5" component="h2">{props.title}</Typography>

        {props.isSim &&
          <IconButton 
            aria-label="Edit" style={{ position: 'absolute', top: -8, right: 8 }}
            onClick={props.onEdit}
          >
            <EditIcon />
          </IconButton>
        }
      </Box>

      {props.data && props.name &&
        <div className="gas-dmg-unit__glance">
          <div className="gas-dmg-unit__portrait">
            <img src={getCharacter(props.name.baseId).image} alt="" className="gas-dmg-unit__src" />
          </div>
          <div className="gas-dmg-unit__levels">
            <span className="gas-dmg-unit__level"><StarIcon style={{ position: 'relative', top: -2 }} fontSize="small" />{props.data.rarity}</span>
            <span className="gas-dmg-unit__level">G{props.data.gear}</span>
            {props.data.gear === 13 &&
              <span className="gas-dmg-unit__level">R{props.data.relic.currentTier - 2}</span>
            }
          </div>
          {(props.data.leader || props.data.modifiers) &&
            <div className="gas-dmg-unit__modifiers">
              {props.data.leader && !(props.data.leader.value === 'OTHER' || props.data.leader === '0') &&
                <div className="gas-dmg-modifier">
                  <img src={getCharacter(props.data.leader.value).image} alt="" className="gas-dmg-modifier__src" />
                </div>
              }
              {props.data.modifiers.length > 0 && props.data.modifiers.map((m, i) =>
                <div className="gas-dmg-modifier" key={i}>
                  <img src={`/images/${m.name}.png`} alt="" className="gas-dmg-modifier__src" />
                </div>
              )}
            </div>
          }
        </div>
      }

      {props.data && props.data.mods &&
        <div className="gas-dmg-unit__mods">
          {props.data.mods.map((m, i) =>
            <DamageMod data={m} key={i} />
          )}
        </div>
      }

      {props.stats && props.data &&
        <div className="gas-dmg-unit__stats">
          <DamageStats
            base={props.stats.base} mods={props.stats.mods}
            leader={props.data.leader} modifiers={props.data.modifiers}
          />
        </div>
      }

    </div>
  );
}

export default DamageUnit;