import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import AddIcon from '@material-ui/icons/Add';
import RemoveCircle from '@material-ui/icons/RemoveCircle';

import './style.scss';

const Toon = (props) => {
  const theme = useTheme();  
  const useStyles = makeStyles({
    scrollPaper: {
      alignItems: 'flex-start',
      [theme.breakpoints.up('md')]: {
        alignItems: 'center'
      },
    }
  });

  const classes = useStyles();
  const [complete, setComplete] = useState(false);

  const onAddChange = (e, v) => {
    setComplete(false);
    props.onAdd && props.onAdd(e, v);
  }

  return (
    <Box className="gas-toon-image">
      {props.src ?
        <Box className="gas-toon-image__container">
          <img src={`https://grandarenascience.com/assets/${props.src}.png`} alt="" className="gas-toon-image__src" />
          {props.remove &&
            <Box className="gas-toon-image__remove">
              <IconButton onClick={props.onRemove} style={{ padding: 0 }}>
                <RemoveCircle fontSize="small" />
              </IconButton>
            </Box>
          }
          
          {props.attack && <Avatar src='/images/use-attack.png' alt='Offense' className='gas-toon-image__avatar gas-toon-image__avatar--primary' />}
          {props.defense && <Avatar src='/images/use-defense.png' alt='Defense' className='gas-toon-image__avatar gas-toon-image__avatar--secondary' />}
          {props.omicron && <Box className="gas-toon-image__omicron" />}
        </Box>
        : props.add &&
        <Box className="gas-toon-image__frame">
          <Box className="gas-toon-image__add">
            <IconButton onClick={() => setComplete(true)}>
              <AddIcon fontSize="large" />
            </IconButton>
          </Box>
        </Box>
      }
      {props.add && complete &&
        <Dialog onClose={() => setComplete(false)} open={complete} classes={{ scrollPaper: classes.scrollPaper }}>
          <DialogTitle>
            <Box className="gas-toon-image__title">Add Unit
              <IconButton aria-label="close" className="gas-toon-image__close" onClick={() => setComplete(false)}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <Box className="gas-toon-image__complete">
            <Autocomplete
              id="toonAdd"
              options={props.list}
              getOptionLabel={(option) => option.name}
              style={{ width: 240 }}
              renderInput={(params) => <TextField {...params} autoFocus label="Select or Search" variant="filled" color="primary" />}
              onChange={onAddChange}
            />
          </Box>
        </Dialog>
      }
    </Box>
  );
}

export default Toon;