import React from 'react';
import { NavLink } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Box from '@material-ui/core/Box';
import GroupIcon from '@material-ui/icons/Group';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import BuildIcon from '@material-ui/icons/Build';
import LinkIcon from '@material-ui/icons/Link';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
  resLabel: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      flexDirection: 'column'
    }
  },
  button: {
    justifyContent: 'flex-start',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'center',
    }
  },
  buttonGroup: {
    textAlign: 'left',
    paddingLeft: 11,
    paddingRight: 11,
    [theme.breakpoints.up('sm')]: {
      textAlign: 'center',
    }
  },
  icon: {
    fontSize: 48,
    marginRight: 16,
    [theme.breakpoints.up('sm')]: {
      marginRight: 0,
      marginBottom: 8
    }
  }
}));

const ResourcesMenu = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));

  const links = [
    { label: 'Roster Development', to: 'roster', icon: <GroupIcon className={classes.icon} /> },
    { label: 'Guides', to: 'guides', icon: <LiveHelpIcon className={classes.icon} /> },
    { label: 'Meta', to: 'meta', icon: <EqualizerIcon className={classes.icon} /> },
    { label: 'Tools', to: 'tools', icon: <BuildIcon className={classes.icon} /> },
    { label: 'Links', to: 'links', icon: <LinkIcon className={classes.icon} /> }
  ];

  return (
    <Box py={4} align="center" bgcolor="background.paper">
      <Container maxWidth="xl">
        <ButtonGroup
          orientation={smUp ? 'horizontal' : 'vertical'} fullWidth
          className={classes.buttonGroup}
          variant="text"
        >
          {links.map((l, i) =>
            <Button
              key={i} size="large" to={`/resources/browse/${l.to}/`}
              classes={{ label: classes.resLabel, root: classes.button }}
              component={NavLink} onClick={props.resetFilters}
              variant={props.type === l.to ? 'contained' : 'text'}
            >
              {l.icon}
              {l.label}
            </Button>
          )}
        </ButtonGroup>
      </Container>
    </Box>
  );
}

export default ResourcesMenu;