import React from 'react';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import BeforeEachFight from './assets/BEF_FINAL_23March.png';
import LinkList from '../../components/linkList';


const links = [
    {
      title: 'Join discord to ask questions and talk to experts',
      description: 'Grand Arena Science discord',
      type: 'web',
      url: 'https://discord.gg/29yYq2JT57'
    },
    
];

export default function BeforeEachFightPage() {

  return (
    <Box>

      <Box py={4}>
        <Container maxWidth="md">
          <Typography align="center" variant="h4" component="h2" gutterBottom>
            Before Each Fight.
        </Typography>
          <Typography align="left" variant="h6" component="p" gutterBottom>
            Before each fight; this is a basic checklist to review before you enter each and every battle during GAC (or arena, or TW).  These steps are not especially complicated, but one of the most frequent causes of losing a fight is because you forgot to check speeds, mods or simply didn't know how the fight would go.  With all of the resources available, there's no reason that has to happen.  Use your BOOTS!  
          </Typography>
        </Container>
      </Box>

      <Box py={4} bgcolor="background.paper">
        <Container maxWidth="md">
          <img src={BeforeEachFight} alt="Before Each Fight checklist" />
        </Container>
      </Box>

      <Box py={4}>
        <Container maxWidth="md">
          <Typography align="center" variant="h4" component="h2" gutterBottom>
            Links.
          </Typography>
          <LinkList items={links} />
        </Container>
      </Box>

    </Box>
  );
}