import React, { useState } from 'react';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Tooltip from '@material-ui/core/Tooltip';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import YouTubeIcon from '@material-ui/icons/YouTube';
import Chip from '@material-ui/core/Chip';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import BarChartIcon from '@material-ui/icons/BarChart';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import SpeedIcon from '@material-ui/icons/Speed';
import VoiceChatIcon from '@material-ui/icons/VoiceChat';

import Toon from '../toon';
import CounterDialog from './dialog';
import DcNote from './dcNote';


const useStyles = makeStyles({
  cardChip: {
    border: 'none'
  },
  buttonChip: {
    marginLeft: '6px',
    padding: '18px 0',
    borderRadius: '18px'
  },
  chipPurple: {
    color: '#ce93d8 !important',
  },
  iconPurple: {
    fill: '#ce93d8 !important'
  },
  chipGreen: {
    color: '#66bb6a !important',
  },
  iconGreen: {
    fill: '#66bb6a !important'
  },
  chipLabel: {
    padding: '0'
  },
  chipIcon: {
    margin: '0 6px'
  }
});

const CounterCard = (props) => {

  const [open, setOpen] = useState(false);
  const attTeam = props.data.attackingTeam;
  const defTeam = props.data.defendingTeam;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();

  return (
    <Card className="gas-card-counter">
      <CardContent>
        <Box className='gas-card-counter__wrapper'>
          {props.data.equippedAttackingDatacron && <DcNote data={props.data.equippedAttackingDatacron} />}
          <Box className={classNames('gas-card-counter__team', props.type === 'fleet' && 'gas-card-counter__team--fleet')}>
            <Toon src={attTeam.leader.imageReference} type={props.type} />
            {attTeam.teamUnits[1] && <Toon src={attTeam.teamUnits[1].unitDefinition.imageReference} type={props.type} />}
            {attTeam.teamUnits[2] && <Toon src={attTeam.teamUnits[2].unitDefinition.imageReference} type={props.type} />}
            {attTeam.teamUnits[3] && <Toon src={attTeam.teamUnits[3].unitDefinition.imageReference} type={props.type} />}
            {attTeam.teamUnits[4] && <Toon src={attTeam.teamUnits[4].unitDefinition.imageReference} type={props.type} />}
            {attTeam.teamUnits[5] && <Toon src={attTeam.teamUnits[5].unitDefinition.imageReference} type={props.type} />}
            {attTeam.teamUnits[6] && <Toon src={attTeam.teamUnits[6].unitDefinition.imageReference} type={props.type} />}
            {attTeam.teamUnits[7] && <Toon src={attTeam.teamUnits[7].unitDefinition.imageReference} type={props.type} />}
            {attTeam.teamUnits[8] && <Toon src={attTeam.teamUnits[8].unitDefinition.imageReference} type={props.type} />}
          </Box>
        </Box>
        <Typography align="center" color="textSecondary" variant="h5" gutterBottom>
          vs
        </Typography>

        <Box className='gas-card-counter__wrapper'>
          {props.data.equippedDefendingDatacron && <DcNote data={props.data.equippedDefendingDatacron} />}
          <Box className={classNames('gas-card-counter__team', props.type === 'fleet' && 'gas-card-counter__team--fleet')}>
            <Toon src={defTeam.leader.imageReference} type={props.type} />
            {defTeam.teamUnits[1] && <Toon src={defTeam.teamUnits[1].unitDefinition.imageReference} type={props.type} />}
            {defTeam.teamUnits[2] && <Toon src={defTeam.teamUnits[2].unitDefinition.imageReference} type={props.type} />}
            {defTeam.teamUnits[3] && <Toon src={defTeam.teamUnits[3].unitDefinition.imageReference} type={props.type} />}
            {defTeam.teamUnits[4] && <Toon src={defTeam.teamUnits[4].unitDefinition.imageReference} type={props.type} />}
            {defTeam.teamUnits[5] && <Toon src={defTeam.teamUnits[5].unitDefinition.imageReference} type={props.type} />}
            {defTeam.teamUnits[6] && <Toon src={defTeam.teamUnits[6].unitDefinition.imageReference} type={props.type} />}
            {defTeam.teamUnits[7] && <Toon src={defTeam.teamUnits[7].unitDefinition.imageReference} type={props.type} />}
            {defTeam.teamUnits[8] && <Toon src={defTeam.teamUnits[8].unitDefinition.imageReference} type={props.type} />}
          </Box>
        </Box>
      </CardContent>
      <CardActions disableSpacing>
        <Box className="gas-card-counter__actions">
          <Box className="gas-card-counter__stats">
            <Tooltip title="Banners" placement="top">
              <Chip
                className={classes.cardChip} label={props.data.banners} icon={<BookmarkIcon />}
                color={props.data && props.data.banners <= '15' ? 'secondary' : 'default'}
                variant="outlined"
              />
            </Tooltip>
            <Tooltip title="Difficulty" placement="top">
              <Chip
                className={classes.cardChip} label={props.data.difficulty.name}
                icon={<SpeedIcon />}
                variant="outlined"
              />
            </Tooltip>
            <Tooltip title="Video Type" placement="top">
              <Chip
                className={classNames(classes.cardChip, props.data && props.data.videoType.name === 'Lab' && classes.chipPurple, props.data && props.data.videoType.name === 'Guide' && classes.chipGreen)}
                label={props.data.videoType.name}
                icon={<VoiceChatIcon />}
                variant="outlined"
                classes={{ icon: classNames(props.data && props.data.videoType.name === 'Lab' && classes.iconPurple, props.data && props.data.videoType.name === 'Guide' && classes.iconGreen) }}
              />
            </Tooltip>
          </Box>
          <Box className="gas-card-counter__links">
            <Tooltip title="View Video" placement="top">
              <Chip
                icon={<YouTubeIcon />} className={classes.buttonChip}
                onClick={() => window.open(props.data.videoLink)}
                classes={{ label: classes.chipLabel, icon: classes.chipIcon }}
              />
            </Tooltip>
            <Tooltip title="View Meta Report" placement="top">
              <Chip
                icon={<BarChartIcon />} className={classes.buttonChip}
                onClick={() => window.open(props.data.metaReportLink)}
                classes={{ label: classes.chipLabel, icon: classes.chipIcon }}
              />
            </Tooltip>
            {props.data.note &&
              <Tooltip title="View Notes" placement="top">
                <Chip
                  icon={<SpeakerNotesIcon />} className={classes.buttonChip}
                  onClick={handleClickOpen}
                  classes={{ label: classes.chipLabel, icon: classes.chipIcon }}
                />
              </Tooltip>
            }
            {props.data.note &&
              <CounterDialog
                open={open} onClose={handleClose} data={props.data.note.description}
              />
            }
          </Box>
        </Box>
      </CardActions>
    </Card>
  );
}

export default CounterCard;