
import { useState } from 'react'
import Snackbar from '@material-ui/core/Snackbar';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

import Review from './review'
import Alert from '../alert';

const SubmitCounter = (props) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState({ text: '', severity: 'error' });
  const { units, datacrons, datasets, ships } = props

  return (
    <>
      <Snackbar
        open={message.text ? true : false} autoHideDuration={6000}
        onClose={() => setMessage({ text: '', severity: 'error' })}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setMessage({ text: '', severity: 'error' })} severity={message.severity}>{message.text}</Alert>
      </Snackbar>

      <Fab variant="extended" color='primary' onClick={() => setOpen(true)} style={{ position: 'fixed', right: 16, bottom: 16 }}>
        <AddIcon style={{ marginRight: 2}} />
        Submit Counter
      </Fab>
      
      {open && <Review units={units} setOpen={setOpen} datacrons={datacrons} datasets={datasets} ships={ships} setMessage={setMessage} />}
    </>
  );
}

export default SubmitCounter;