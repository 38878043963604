import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

var firebaseConfig = {
  apiKey: "AIzaSyDXKjBvyJKmmErOM1EjG4A4bstuBr3KpWc",
  authDomain: "grand-arena-science.firebaseapp.com",
  projectId: "grand-arena-science",
  storageBucket: "grand-arena-science.appspot.com",
  messagingSenderId: "1080747355707",
  appId: "1:1080747355707:web:2ef884dc22739085edf61e",
  measurementId: "G-HEMJ3N312L"
};

const firebaseApp=firebase.initializeApp(firebaseConfig);
const auth = firebase.auth().signInAnonymously()
.then(() => {
  // Signed in..
})
.catch((error) => {
  var errorCode = error.code;
  var errorMessage = error.message;
  console.log({errorCode, errorMessage});
});
const db=firebase.firestore();

export { 
  db,
  firebaseApp,
  auth 
};