import React from 'react';

import Tooltip from '@material-ui/core/Tooltip';
import Avatar from '@material-ui/core/Avatar';

const DcAvatar = (props) => {

  return (
    <div className='gas-dc-level'>
      {props.data &&
        <Tooltip title={props.data.description} placement="top">
          <Avatar
            src={`https://grandarenascience.com/assets/${props.data.image}.png`}
            alt="" className='gas-dc-level__avatar'
          />
        </Tooltip>
      }
      {props.data?.nickname}
    </div>
  );
}

export default DcAvatar;