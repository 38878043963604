import React from 'react';
import { withRouter } from "react-router";
import { NavLink } from 'react-router-dom';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';

import Datacrons from '../components/datacrons';

const DatacronsPage = (props) => {

  return (
    <Box>
      <Container>
        <Box my={4}>
          <Typography align="center" variant="h4" component="h1" gutterBottom>
            Datacron Recommendations
          </Typography>
          <Typography align="left" variant="h6" component="h2" gutterBottom>
            This guide is meant to be an overview for what datacron stats and abilities are best for each team. This will hopefully help you in two ways: what datacrons to farm and what datacrons to apply to your teams.
          </Typography>
          <Typography align="left" variant="h6" component="h2" gutterBottom>
            Stats are sorted by priority starting from the left. This means that the <Box fontWeight="bold" component="span">stat you want the most is the one furthest to the left.</Box> The stat second to the left is the next highest priority, so on and so forth.
          </Typography>
          <Typography align="left" variant="h6" component="h2" gutterBottom>
            <Box fontWeight="bold" component="span">Keep in mind that these are general recommendations. There may be specific counters where you would want different stats or abilities than what is listed here</Box> (or the priority of the stats could be different). There are a number of cases where a team would benefit from multiple abilities, and which one is best depends on the situation. For example, Inquisitors can benefit greatly from the two set 6 level 3 abilities: the 20% TM as well as the tank revive.
          </Typography>
          <Typography align="left" variant="h6" component="h2" gutterBottom>
            <Box fontWeight="bold" component="span">As with everything in SWGOH, keep your roster and strategy in mind when assessing this guide.</Box> Nobody has identical rosters or playstyles. Aim for what suits you.
          </Typography>
          <Box>
            <Box display='flex' justifyContent='space-between' marginTop={2}>
              <ButtonGroup>
                <Button component={NavLink} variant={props.type === '5v5' && 'contained'} to="/datacrons/">5v5</Button>
                <Button component={NavLink} variant={props.type === '3v3' && 'contained'} to="/datacrons/3v3/">3v3</Button>
              </ButtonGroup>
              <Box display='flex' >
                <Box display='flex' alignItems='center'>
                  <Avatar src='/images/use-attack.png' alt='Offense' className='gas-toon-image__avatar gas-toon-image__avatar--primary gas-toon-image__avatar--inline' />
                  <Box marginLeft={1} marginRight={4}>= Use on Offense</Box>
                </Box>
                <Box display='flex' alignItems='center'>
                  <Avatar src='/images/use-defense.png' alt='Defense' className='gas-toon-image__avatar gas-toon-image__avatar--secondary gas-toon-image__avatar--inline' />
                  <Box marginLeft={1}>= Use on Defense</Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Datacrons type={props.type} />
        </Box>
      </Container>
    </Box>
  );
}

export default withRouter(DatacronsPage);