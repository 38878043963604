import React, { useState } from 'react';

import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';

import CounterDialog from './dialog';
import { Typography } from '@material-ui/core';

const DcNote = (props) => {

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getLevel = (l) => {
    let level = '';

    if (l.includes('datacron_alignment')) {
      level = '3';
    } else if (l.includes('datacron_faction')) {
      level = '6';
    } else if (l.includes('datacron_character')) {
      level = '9';
    }
    return level;
  }

  return (
    <Box className='gas-card-counter__dc'>
      <Avatar
        src={`https://grandarenascience.com/assets/${props.data.datacronSet.image}.png`}
        alt="" className='gas-card-counter__avatar' onClick={handleClickOpen}
      />
      <CounterDialog
        open={open} onClose={handleClose} title="Datacron Details"
      >
        <Box display='flex' alignItems='center'>
          <Box>
            <Avatar
              src={`https://grandarenascience.com/assets/${props.data.datacronSet.image}.png`}
              alt="" className='gas-card-note__avatar'
            />
          </Box>
          <Box pl={2}>
            <Typography variant='button'>{props.data.datacronSet.name}</Typography>
          </Box>
        </Box>
        {props.data.equippedDatacronAbilities.length > 0 && props.data.equippedDatacronAbilities.map((d, i) =>
          <Box key={i} mt={3}>
            <Divider />
            <Box display='flex' alignItems='center' mt={3}>
              <Box>
                <Avatar
                  src={`https://grandarenascience.com/assets/${d.datacronAbility.image}.png`}
                  alt=""
                />
              </Box>
              <Box pl={2}>
                <Typography variant='subtitle2'>Level {getLevel(d.datacronAbility.name)}</Typography>
                <Typography>{d.datacronAbility.description}</Typography>
              </Box>
            </Box>
          </Box>
        )}

      </CounterDialog>
    </Box>
  );
}

export default DcNote;