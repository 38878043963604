import React from 'react';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import MMJPG from './assets/GACMatchmaking 11May FINAL.png';
import LinkList from '../../components/linkList';


const links = [
    {
        title: 'Matchmaking; an Overview in detail.  Learn more than you ever thought you wanted to know, with Free88',
        description: 'Matchmaking in great detail',
        type: 'video',
        url: 'https://www.youtube.com/watch?v=YIF1_wEUixw'
    },
    {
      title: 'Join discord to ask questions and talk to experts',
      description: 'Grand Arena Science discord',
      type: 'web',
      url: 'https://discord.gg/29yYq2JT57'
    },
];


export default function MatchmakingPage() {

    return (
        <Box>
            <Box py={4}>
                <Container maxWidth="md">
                    <Typography align="center" variant="h4" component="h2" gutterBottom>
                        Grand Arena Matchmaking.
                    </Typography>
                    <Typography align="left" variant="h6" component="p" gutterBottom>
                        Welcome to a summary of how we THINK matchmaking works.  We looked at over 400 rosters worth of GAC data and here's what we found, as well as how you can use it to improve your own matchmaking.  If you really want to geek out, take a look at the YouTube link below the image where we have a ~30 minute video with a much more detailed explanation of the project, data sources, assumptions, processes, next steps and more.
                    </Typography>
                </Container>
            </Box>
    
            <Box py={4} bgcolor="background.paper">
                <Container maxWidth="md">
                        <img src={MMJPG} alt="Damage Mitigation Overview" />
                </Container>
            </Box>
    
            <Box py={4}>
                <Container maxWidth="md">
                    <Typography align="center" variant="h4" component="h2" gutterBottom>
                        Links.
                    </Typography>
                    <LinkList items={links} />
                </Container>
            </Box>
  
        </Box>
    );
}

