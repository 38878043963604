import { useState } from 'react'

import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Box } from '@material-ui/core'

const Datacrons = (props) => {
  const { options, level, dcLevel, onChange, type, selected } = props
  const [inputValue, setInputValue] = useState('')

  return (
    <Box marginBottom={3}>
      <Autocomplete
        value={selected}
        onChange={(e, newValue) => {
          onChange(newValue, type, dcLevel)
        }}
        inputValue={inputValue}
        onInputChange={(e, newInputValue) => {
          setInputValue(newInputValue)
        }}
        getOptionLabel={(option) => option ? option.description : ''}
        id={`searchDcs${type}${level}`}
        options={options}
        sx={{ width: '100%' }}
        renderInput={(params) => <TextField {...params} label={`Level ${level}`} variant="outlined" />}
        //isOptionEqualToValue={(option, value) => option && value && option.description === value.description}
      />
    </Box>
  );
}

export default Datacrons;