import React, { useState } from 'react';
import classNames from 'classnames';

import ChangeMod from '../sim/changeMod';
import { getModStat } from '../../../utils/mods';

const DamageMod = (props) => {
  const [show, setShow] = useState(false);

  const closeDialog = () => {
    setShow(false);
  }

  return (
    <div
      className={classNames(`gas-dmg-mod gas-dmg-mod--${props.data.slot}`, props.changeMod && 'gas-dmg-mod--change')}
      onClick={() => props.changeMod && setShow(true)}
    >
      <div className={`gas-dmg-mod__icon gas-dmg-mod__icon--${props.data.set}`} />
      <div className="gas-dmg-mod__primary">
        <div className="gas-dmg-mod__stat">{props.data.primaryStat.value}{props.data.primaryStat.unitStat !== 5 && '%'}</div>
        <div className="gas-dmg-mod__type">{getModStat(props.data.primaryStat.unitStat)}</div>
      </div>
      {show && <ChangeMod close={closeDialog} changeMod={props.changeMod} data={props.data} />}
    </div>
  );
}

export default DamageMod;