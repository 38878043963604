import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';

import CountersPage from './pages/counters';
import HomePage from './pages/home';
import DamagePage from './pages/damage';
import ResourcesPage from './pages/resources';
import BountyHuntersPage from './pages/overviews/bounty-hunters';
import ImperialTroopersPage from './pages/overviews/imperial-troopers';
import BeforeEachFightPage from './pages/overviews/before-each-fight';
import BeginnersPage from './pages/overviews/beginners';
import ScoutingPage from './pages/overviews/scouting';
import DamageMitigationPage from './pages/overviews/damit';
import MatchmakingPage from './pages/overviews/matchmaking';
import LeaderSplitsPage from './pages/overviews/leadersplits';
import SoloGLPage from './pages/overviews/sologl';
import ComingsoonPage from './pages/comingsoon';
import ContactPage from './pages/contact';
import PrivacyPage from './pages/privacy';
import Navigation from './components/navigation';
import Hero from './components/hero';
import GABuddyPosterPage from './pages/overviews/gabuddy';
import DatacronsPage from './pages/datacrons';

const useStyles = makeStyles((theme) => ({
  divider: {
    margin: theme.spacing(0, 0, 3, 0),
  },
}));

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://grandarenascience.com/">
        GrandArenaScience.com
      </Link>{' '}
      {new Date().getFullYear()}
      {' __ '}
      <Link color="inherit" href="https://www.grandarenascience.com/privacy/">
        Privacy Policy
      </Link>
    </Typography>
  );
}

export default function App() {
  const classes = useStyles();

  return (
    <Router>
      <Box>
        <Navigation />
        <Hero short />
        <Switch>
          <Route exact path="/">
            <HomePage />
          </Route>
          <Route exact path="/counters/">
            <CountersPage type="5v5" />
          </Route>
          <Route exact path="/counters/3v3/">
            <CountersPage type="3v3" />
          </Route>
          <Route exact path="/counters/fleet/">
            <CountersPage type="fleet" />
          </Route>
          <Route exact path="/resources/" render={(props) => <ResourcesPage {...props} />} />
          <Route exact path="/resources/browse/:type/" render={(props) => <ResourcesPage {...props} />} />
          {/*<Route exact path="/resources/roster-development/">
            <RosterPage />
          </Route>
          <Route exact path="/resources/guides/">
            <GuidesPage />
          </Route>
          <Route exact path="/resources/meta/">
            <MetaPage />
          </Route>
          <Route exact path="/resources/tools/">
            <ToolsPage />
          </Route>
          */}
          <Route exact path="/resources/bounty-hunters/">
            <BountyHuntersPage />
          </Route>
          <Route exact path="/resources/imperial-troopers/">
            <ImperialTroopersPage />
          </Route>
          <Route exact path="/resources/before-each-fight/">
            <BeforeEachFightPage />
          </Route>
          <Route exact path="/resources/scouting/">
            <ScoutingPage />
          </Route>
          <Route exact path="/resources/beginners/">
            <BeginnersPage />
          </Route>
          <Route exact path="/resources/damit/">
            <DamageMitigationPage />
          </Route>
          <Route exact path="/resources/matchmaking/">
            <MatchmakingPage />
          </Route>
          <Route exact path="/resources/sologl/">
            <SoloGLPage />
          </Route>
          <Route exact path="/resources/leadersplits/">
            <LeaderSplitsPage />
          </Route>
          <Route exact path="/comingsoon/">
            <ComingsoonPage />
          </Route>    
          <Route exact path="/contact/">
            <ContactPage />
          </Route> 
          <Route exact path="/privacy/">
            <PrivacyPage />
          </Route>     
          <Route exact path="/damage/">
            <DamagePage />
          </Route>
          <Route exact path="/datacrons/">
            <DatacronsPage type="5v5" />
          </Route>
          <Route exact path="/datacrons/3v3/">
            <DatacronsPage type="3v3" />
          </Route>
          <Route exact path="/gabuddy/">
            <GABuddyPosterPage />
          </Route>     
        </Switch>

        <Divider className={classes.divider} />
        <Box pb={3}>
          <Copyright />
        </Box>
      </Box>
    </Router>
  );
}