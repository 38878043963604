import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import LanguageIcon from '@material-ui/icons/Language';
import PhotoIcon from '@material-ui/icons/Photo';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';

import './style.scss';

const BountyHuntersPage = (props) => {

  return (
    <List className="gas-list">
      {props.items && props.items.length > 0 && props.items.map((item, i) => 
        <ListItem 
          className="gas-list__item" key={i} button
          onClick={() => window.open(item.url, '_blank')}
        >
          <ListItemAvatar>
            <Avatar>
              {item.type === 'web' && <LanguageIcon />}
              {item.type === 'photo' && <PhotoIcon />}
              {item.type === 'video' && <VideoLibraryIcon />}
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={item.title} secondary={item.description} />
        </ListItem>
      )}
    </List>
  );
}

export default BountyHuntersPage;