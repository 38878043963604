export const getModSet = (m) => {
    switch (m) {
        case 2: {
            return 'Offense';
        }
        case 3: {
            return 'Defense';
        }
        case 4: {
            return 'Speed';
        }
        case 5: {
            return 'Crit Chance';
        }
        case 6: {
            return 'Crit Damage';
        }
        default: {
            return 'Health';
        }
    }
};

export const getModSlot = (m) => {
    switch (m) {
        case 2: {
            return 'Arrow';
        }
        case 3: {
            return 'Diamond';
        }
        case 4: {
            return 'Triangle';
        }
        case 5: {
            return 'Circle';
        }
        case 6: {
            return 'Cross';
        }
        default: {
            return 'Square';
        }
    }
};

export const getModStat = (m) => {
    switch (m) {
        case 48: {
            return 'Offense';
        }
        case 49: {
            return 'Defense';
        }
        case 5: {
            return 'Speed';
        }
        case 54: {
            return 'Critical Avoidance';
        }
        case 53: {
            return 'Critical Chance';
        }
        case 17: {
            return 'Potency';
        }
        case 18: {
            return 'Tenacity';
        }
        case 16: {
            return 'Critical Damage';
        }
        case 56: {
            return 'Protection';
        }
        default: {
            return 'Health';
        }
    }
};

export const getModValue = (rarity, id) => {
    switch (id) {
        case 48: {
            return rarity === 6 ? '8.5' : '5.88';
        }
        case 49: {
            return rarity === 6 ? '20' : '11.75';
        }
        case 5: {
            return rarity === 6 ? '32' : '30';
        }
        case 54: {
            return rarity === 6 ? '35' : '24';
        }
        case 53: {
            return rarity === 6 ? '20' : '12';
        }
        case 17: {
            return rarity === 6 ? '30' : '24';
        }
        case 18: {
            return rarity === 6 ? '35' : '24';
        }
        case 16: {
            return rarity === 6 ? '42' : '36';
        }
        case 56: {
            return rarity === 6 ? '24' : '23.5';
        }
        default: {
            return rarity === 6 ? '16' : '5.88';
        }
    }
};