import { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import isURL from 'validator/lib/isURL'

import TextField from '@material-ui/core/TextField'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import { datacronSelected } from './utils'
import Datacrons from './datacrons'
import DataSet from './dataset'
import SquadSelection from './squadSelection'

const CounterForm = (props) => {
  const { levelThree, levelSix, levelNine, onCounterSubmit, onCancel, datasets, attackingUnits, defendingUnits, units, onUnitChange, setDefendingUnits, setAttackingUnits, ships, onUnitRemove } = props
  const [attackingDcs, setAttackingDcs] = useState([])
  const [defendingDcs, setDefendingDcs] = useState([])
  const [attackingSet, setAttackingSet] = useState(null)
  const [defendingSet, setDefendingSet] = useState(null)

  const defaultValues = {
    season: '',
    attempt: '',
    banners: '',
    video: '',
    gameMode: '5V5',
    difficulty: 'EASY',
    notes: '',
  }

  const { handleSubmit, watch, control, formState: { errors } } = useForm({ defaultValues });

  const onSubmit = (form, e) => handleUpdate(form, e);

  const handleUpdate = (form, e) => {
    onCounterSubmit(form, attackingSet, attackingDcs, defendingSet, defendingDcs)
  }

  const changeDc = (v, type, level) => {
    let updateDc = []
    let getState = type === 'attacking' ? attackingDcs : defendingDcs
    if (v) {
      let removeDc = getState.filter((d) => d.datacronAbility.name.includes(level) !== v.name.includes(level))
      updateDc = [...removeDc, { datacronAbility: v }]
    } else {
      updateDc = getState.filter((d) => !d.datacronAbility.name.includes(level))
    }
    type === 'attacking' ? setAttackingDcs(updateDc) : setDefendingDcs(updateDc)
  }

  const changeSet = (v, type) => {
    type === 'attacking' ? setAttackingSet(v) : setDefendingSet(v)
  }

  return (
    <Box component='form' onSubmit={handleSubmit(onSubmit)}>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Controller
            render={({ field }) => (
              <RadioGroup
                aria-label='format' row {...field}
                onChange={(e) => {
                  setDefendingUnits([])
                  setAttackingUnits([])
                  field.onChange(e)
                }}
              >
                <FormControlLabel value='5V5' control={<Radio color="primary" />} label='5v5' />
                <FormControlLabel value='3V3' control={<Radio color="primary" />} label='3v3' />
                <FormControlLabel value='SHIPS' control={<Radio color="primary" />} label='Ships' />
              </RadioGroup>
            )}
            name='gameMode' control={control}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            render={({ field }) => (
              <RadioGroup aria-label='difficulty' row {...field}>
                <FormControlLabel value='EASY' control={<Radio color="primary" />} label='Easy' />
                <FormControlLabel value='NORMAL' control={<Radio color="primary" />} label='Normal' />
                <FormControlLabel value='COMPLEX' control={<Radio color="primary" />} label='Complex' />
              </RadioGroup>
            )}
            name='difficulty' control={control}
          />
        </Grid>
        <Grid item xs={4} md={2}>
          <Controller
            render={({ field }) =>
              <TextField
                {...field} error={errors.season ? true : false} style={{ margin: 0 }}
                label='Season' variant='filled' fullWidth margin='normal'
              />
            }
            name='season' control={control} rules={{ required: true }}
          />
        </Grid>
        <Grid item xs={4} md={2}>
          <Controller
            render={({ field }) =>
              <TextField
                {...field} error={errors.attempt ? true : false} style={{ margin: 0 }}
                label='Attempt' variant='filled' fullWidth margin='normal'
              />
            }
            name='attempt' control={control} rules={{ required: true }}
          />
        </Grid>
        <Grid item xs={4} md={2}>
          <Controller
            render={({ field }) =>
              <TextField
                {...field} error={errors.banners ? true : false} style={{ margin: 0 }}
                label='Banners' variant='filled' fullWidth margin='normal'
              />
            }
            name='banners' control={control} rules={{ required: true }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            render={({ field }) =>
              <TextField
                {...field} error={errors.video ? true : false} style={{ margin: 0 }}
                label='Video' variant='filled' fullWidth margin='normal'
              />
              
            }
            name='video' control={control} rules={{ required: true, validate: {
              isURL: v => isURL(v, { require_protocol: true })
            }}}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            render={({ field }) =>
              <TextField
                {...field} error={errors.notes ? true : false} style={{ margin: 0 }}
                label='Notes' variant='filled' fullWidth margin='normal'
              />
            }
            name='notes' control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <SquadSelection
            attackingUnits={attackingUnits} defendingUnits={defendingUnits} ships={ships}
            units={units} onUnitChange={onUnitChange} gameMode={watch("gameMode")} onUnitRemove={onUnitRemove}
          />
        </Grid>
        {watch("gameMode") !== 'SHIPS' && <>
          <Grid item xs={12} sm={6}>
            <Typography variant='h6'>Attacking DCs</Typography>
            <DataSet
              options={datasets} selected={attackingSet}
              type='attacking' onChange={changeSet}
            />
            <Datacrons
              level='3' options={levelThree}
              selected={datacronSelected('datacron_alignment', attackingDcs)}
              dcLevel='datacron_alignment'
              onChange={changeDc} type='attacking'
            />
            <Datacrons
              level='6' options={levelSix}
              selected={datacronSelected('datacron_faction', attackingDcs)}
              dcLevel='datacron_faction'
              onChange={changeDc} type='attacking'
            />
            <Datacrons
              level='9' options={levelNine}
              selected={datacronSelected('datacron_character', attackingDcs)}
              dcLevel='datacron_character'
              onChange={changeDc} type='attacking'
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant='h6'>Defending DCs</Typography>
            <DataSet
              options={datasets} selected={defendingSet}
              type='defending' onChange={changeSet}
            />
            <Datacrons
              level='3' options={levelThree}
              selected={datacronSelected('datacron_alignment', defendingDcs)}
              dcLevel='datacron_alignment'
              onChange={changeDc} type='defending'
            />
            <Datacrons
              level='6' options={levelSix}
              selected={datacronSelected('datacron_faction', defendingDcs)}
              dcLevel='datacron_faction'
              onChange={changeDc} type='defending'
            />
            <Datacrons
              level='9' options={levelNine}
              selected={datacronSelected('datacron_character', defendingDcs)}
              dcLevel='datacron_character'
              onChange={changeDc} type='defending'
            />
          </Grid>
        </>}
      </Grid>
      <Grid spacing={2} container>
        <Grid item>
          <Button type='submit' color='primary' variant='contained'>Submit</Button>
        </Grid>
        <Grid item>
          <Button color='primary' variant='outlined' onClick={onCancel}>Cancel</Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default CounterForm;