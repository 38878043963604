import React from 'react';
import { withRouter } from "react-router";

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import Counters from '../components/counters';

const CountersPage = (props) => {

  return (
    <Box>
      <Container maxWidth="xl">
        <Box my={4}>
          <Typography align="center" variant="h4" component="h1" gutterBottom>
            Counters.
          </Typography>
          <Typography align="left" variant="h6" component="h2" gutterBottom>
            The searchable database of Grand Arena counters, all individually curated by GAC experts.  The vast majority are from actual GAC matches and every video has been individually selected as a good representation of the match (either winning or losing). Start by selecting the format, selecting defense OR offense sorting and then entering the character(s) on the team you wish to use or counter by clicking on the +.  You can also submit a counter of your own by choosing the "+ Submit Counter" button in the bottom right.
          </Typography>
          <Counters key={props.location.pathname} type={props.type} />
        </Box>
      </Container>
    </Box>
  );
}

export default withRouter(CountersPage);