import React, { useState } from 'react';

import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  label: {
    fontSize: '.875rem',
  }
});

const ChangeAdditional = (props) => {
  const classes = useStyles();
  const [selected, setSelected] = useState([]);

  const onChange = (e) => {
    let value = parseFloat(e.target.value);
    let checked = e.target.checked;
    let name = e.target.name;
    let updateSelected = checked ? [...selected, { value: value, checked: checked, name: name }] : selected.filter((s) => s.name !== name);
    setSelected(updateSelected);
    props.onChange && props.onChange(updateSelected);
  }

  return (
    <Box py={2}>
      <FormControl component="fieldset">
        <FormLabel component="legend">Additional Defense Modifiers</FormLabel>
        <FormGroup>
          {props.isJedi ?
            <FormControlLabel
              value="50"
              control={<Checkbox name="masterTraining" color="primary" onChange={onChange} />}
              label="Master's Training (+50%)"
              classes={{ label: classes.label }}
            />
            :
            <FormControlLabel
              value="25"
              control={<Checkbox name="masterTraining" color="primary" onChange={onChange} />}
              label="Master's Training (+25%)"
              classes={{ label: classes.label }}
            />
          }
          <FormControlLabel
            value="50"
            control={<Checkbox name="beskarArmor" color="primary" onChange={onChange} />}
            label="Beskar Armor (+50%)"
            classes={{ label: classes.label }}
          />
          <FormControlLabel
            value="50"
            control={<Checkbox name="defenseUp" color="primary" onChange={onChange} />}
            label="Defense Up (+50%)"
            classes={{ label: classes.label }}
          />
          <FormControlLabel
            value="-50"
            control={<Checkbox name="defenseDown" color="primary" onChange={onChange} />}
            label="Defense Down (-50%)"
            classes={{ label: classes.label }}
          />
          <FormControlLabel
            value="-25"
            control={<Checkbox name="vandorChewie" color="primary" onChange={onChange} />}
            label="Buffed Vandor Chewbacca (-25%)"
            classes={{ label: classes.label }}
          />
        </FormGroup>
      </FormControl>
    </Box>
  );
}

export default ChangeAdditional;