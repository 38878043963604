import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles({
  closeButton: {
    position: 'absolute',
    right: '8px',
    top: '8px'
  },
  dialogTitle: {
    paddingRight: '96px'
  }
});

const CounterDialog = (props) => {  
  const { onClose, selectedValue, open, data, title, children } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const classes = useStyles();

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle className={classes.dialogTitle} onClose={handleClose}>{title ? title : 'Counter Notes'} 
        <IconButton aria-label="close" onClick={onClose} className={classes.closeButton}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {data && <Typography>{data}</Typography>}
        {children && children}
      </DialogContent>
    </Dialog>
  );
}

export default CounterDialog;