import React, { useState, useEffect } from 'react';
import axios from 'axios';
import statCalculator from 'swgoh-stat-calc';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

import DamageFilters from './filters';
import DamageUnit from './unit';
import DamageSim from './sim';
import DamageResults from './results';
import './style.scss';

const useStyles = makeStyles({
  backdrop: {
    zIndex: 1000,
  },
  alert: {
    marginBottom: 24,
  }
});

const DamageCalculator = (props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [baseUnitName, setBaseUnitName] = useState(null);
  const [units, setUnits] = useState([]);
  const [damage, setDamage] = useState(null);
  const [baseUnitData, setBaseUnitData] = useState(null);
  const [baseUnitStats, setBaseUnitStats] = useState(null);
  const [simOne, setSimOne] = useState(false);
  const [simOneData, setSimOneData] = useState(null);
  const [simOneStats, setSimOneStats] = useState(null);
  const [simTwo, setSimTwo] = useState(false);
  const [simTwoData, setSimTwoData] = useState(null);
  const [simTwoStats, setSimTwoStats] = useState(null);

  useEffect(() => {
    axios.get('https://swgoh-stat-calc.glitch.me/gameData.json')
      .then(response => {
        statCalculator.setGameData(response.data);
      })
      .catch(error => {
        setLoading(false);
        setError(true);
      });

    axios.get('https://www.grandarenascience.com/api/fetchUnitList.php?type=1')
      .then(response => {
        setLoading(false);
        setUnits(response.data);
      })
      .catch(error => {
        setLoading(false);
        setError(true);
      });
  }, []);

  const onSubmit = (mods, leader, modifiers, id) => {
    let updateSim = {
      ...baseUnitData,
      mods: mods,
      leader: leader,
      modifiers: modifiers
    }
    if (id === 1) {
      setSimOneData(updateSim);
      setSimOneStats(statCalculator.calcCharStats(updateSim));
      setSimOne(false);
      !simTwoData && setSimTwo(true);
    } else if (id === 2) {
      setSimTwoData(updateSim);
      setSimTwoStats(statCalculator.calcCharStats(updateSim));
      setSimTwo(false);
    }
  }

  const onEdit = (id) => {
    if (id === 1) {
      setSimOne(true);
    } else if (id === 2) {
      setSimTwo(true);
    }
  }

  const resetSims = () => {    
    setSimOneData(null);
    setSimOneStats(null);
    setSimTwoData(null);
    setSimTwoStats(null);
    setSimOne(false);
    setSimTwo(false);
  }

  const submitFilters = (data) => {
    let apiUrl = `https://www.grandarenascience.com/api/fetchPlayerUnit.php?ally=${data.ally}&unit=${data.unit.baseId}`;
    setLoading(true);
    setError(false);
    setNotFound(false);

    axios.get(apiUrl)
      .then(response => {
        setLoading(false);
        resetSims();
        if (response.data) {
          setBaseUnitName(data.unit);
          setBaseUnitData(response.data);
          setSimOne(true);
          setDamage(data.damage);
          setBaseUnitStats(statCalculator.calcCharStats(response.data));
        } else {
          setNotFound(true);
        }
      })
      .catch(error => {
        setLoading(false);
        setError(true);
      });
  };

  return (
    <Box>
      {units.length > 0 && <DamageFilters onSubmit={submitFilters} units={units} />}
      {error &&
        <Alert className={classes.alert} severity="error">There was a problem with retrieving data.</Alert>
      }
      {notFound &&
        <Alert className={classes.alert} severity="info">The selected character was not found in your roster data.</Alert>
      }
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          {baseUnitData &&
            <>
              <DamageUnit data={baseUnitData} name={baseUnitName} stats={baseUnitStats} title="Baseline Unit" />
              <DamageResults data={baseUnitData} stats={baseUnitStats} damage={damage} />
            </>
          }
        </Grid>
        <Grid item xs={12} md={4}>
          {simOne &&
            <DamageSim showEdit={simOneData} id={1} mods={baseUnitData.mods} onSubmit={onSubmit} name={baseUnitName} />
          }
          {simOneData && !simOne &&
            <>
              <DamageUnit onEdit={() => onEdit(1)} isSim data={simOneData} name={baseUnitName} stats={simOneStats} title="Simulation Unit 1" />
              <DamageResults data={simOneData} stats={simOneStats} damage={damage} />
            </>
          }
        </Grid>
        <Grid item xs={12} md={4}>
          {simTwo &&
            <DamageSim id={2} showEdit={simTwoData} mods={baseUnitData.mods} onSubmit={onSubmit} name={baseUnitName} />
          }
          {simTwoData && !simTwo &&
            <>
              <DamageUnit onEdit={() => onEdit(2)} isSim data={simTwoData} name={baseUnitName} stats={simTwoStats} title="Simulation Unit 2" />
              <DamageResults data={simTwoData} stats={simTwoStats} damage={damage} />
            </>
          }
        </Grid>
      </Grid>

      <Backdrop open={loading} className={classes.backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
}

export default DamageCalculator;