import React from 'react';
import classNames from 'classnames';
import Box from '@material-ui/core/Box';
import './style.scss';

const Hero = (props) => {

  return (
    <Box className={classNames('gas-hero', props.short && 'gas-hero--short')} />
  );
}

export default Hero;