import React from 'react';

import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Box from '@material-ui/core/Box';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const ResourcesFilters = (props) => {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Box className="gas-filters">
      <Box className="gas-filters__group">
        <ButtonGroup
          orientation={smUp ? 'horizontal' : 'vertical'}
          fullWidth={!smUp}
        >
          <Button
            variant={props.category === 'All' && 'contained'}
            onClick={() => { props.setCategory('All'); props.setCreator(null); }}
          >
            All
          </Button>
          {props.type === 'links' &&
            <Button
              variant={props.category === 'Creator' && 'contained'}
              onClick={() => { props.setCategory('Creator'); props.setCreator(null); }}
            >
              Creator
            </Button>
          }
          {props.type === 'links' &&
            <Button
              variant={props.category === 'Discord' && 'contained'}
              onClick={() => { props.setCategory('Discord'); props.setCreator(null); }}
            >
              Discord
            </Button>
          }
          {props.type === 'links' &&
            <Button
              variant={props.category === 'Website' && 'contained'}
              onClick={() => { props.setCategory('Website'); props.setCreator(null); }}
            >
              Website
            </Button>
          }
          {props.type === 'roster' &&
            <Button
              variant={props.category === 'Mods' && 'contained'}
              onClick={() => { props.setCategory('Mods'); props.setCreator(null); }}
            >
              Mods
            </Button>
          }
          {props.type === 'roster' &&
            <Button
              variant={props.category === 'Roster' && 'contained'}
              onClick={() => { props.setCategory('Roster'); props.setCreator(null); }}
            >
              Roster
            </Button>
          }
          {(props.type === 'guides' || props.type === 'meta' || props.type === 'tools') &&
            <Button
              variant={props.category === 'Guides' && 'contained'}
              onClick={() => { props.setCategory('Guides'); props.setCreator(null); }}
            >
              Guides
            </Button>
          }
          {(props.type === 'guides' || props.type === 'tools') &&
            <Button
              variant={props.category === 'Counters' && 'contained'}
              onClick={() => { props.setCategory('Counters'); props.setCreator(null); }}
            >
              Counters
            </Button>
          }
          {props.type === 'guides' &&
            <Button
              variant={props.category === 'Modding' && 'contained'}
              onClick={() => { props.setCategory('Modding'); props.setCreator(null); }}
            >
              Modding
            </Button>
          }
          {props.type === 'meta' &&
            <Button
              variant={props.category === 'Opinion' && 'contained'}
              onClick={() => { props.setCategory('Opinion'); props.setCreator(null); }}
            >
              Opinion
            </Button>
          }
          {props.type === 'meta' &&
            <Button
              variant={props.category === 'Strategy' && 'contained'}
              onClick={() => { props.setCategory('Strategy'); props.setCreator(null); }}
            >
              Strategy
            </Button>
          }
          {props.type === 'tools' &&
            <Button
              variant={props.category === 'GAC Compare' && 'contained'}
              onClick={() => { props.setCategory('GAC Compare'); props.setCreator(null); }}
            >
              GAC Compare
            </Button>
          }
          {props.type === 'tools' &&
            <Button
              variant={props.category === 'Tools' && 'contained'}
              onClick={() => { props.setCategory('Tools'); props.setCreator(null); }}
            >
              Tools
            </Button>
          }
        </ButtonGroup>
      </Box>
      <Box className="gas-filters__group">
        <Autocomplete
          id="creatorAdd"
          options={props.creators}
          getOptionLabel={(option) => option}
          style={{ width: smUp ? 240 : '100%' }}
          renderInput={(params) => <TextField {...params} label="Select Author" variant="filled" color="secondary" />}
          onChange={props.selectCreator}
          value={props.creator}
        />
      </Box>
    </Box>
  );
}

export default ResourcesFilters;