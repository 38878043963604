export const removeDuplicates = (arr) => {
    let uniqueArray = Array.from(new Set(arr));
    let sortArray = uniqueArray.sort((a, b) => a.name.localeCompare(b.name));
    return sortArray;
};

export const removeDuplicateObjects = (value,key,sortBy) => {
    /*let uniqueArray = Array.from(new Set(arr));
    let sortArray = uniqueArray.sort((a, b) => a.name.localeCompare(b.name));
    return sortArray;*/
    let uniqueArray = [...new Map(value.map(x=>[key(x),x])).values()];
    let sortArray = uniqueArray.sort((a, b) => a[sortBy].localeCompare(b[sortBy]));
    return sortArray;
};

export const sortAtoZ = (arr) => {
    let sortArray = arr.sort((a, b) => a.localeCompare(b));
    return sortArray;
};

export const buildCreatorList = (arr) => {
    let getCreators = arr.map(c => c.creator)
    let uniqueArray = Array.from(new Set(getCreators));
    let sortArray = uniqueArray.sort((a, b) => a.localeCompare(b));
    return sortArray;
}