import React, { useState, useEffect } from 'react';
import axios from 'axios';

import Box from '@material-ui/core/Box';
import Backdrop from '@material-ui/core/Backdrop';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import DcStat from './components/stat';
import Toon from '../toon';
import DcAvatar from './components/datacron';
import { convertCsvToJson, getUnitByCode, datacronApi } from '../../utils';
import './style.scss';

const useStyles = makeStyles({
  backdrop: {
    zIndex: 1000,
  }
});

const axiosConfig = {
  headers: {
    'X-API-KEY': 'ReadOnly'
  }
};

const Datacrons = (props) => {
  const classes = useStyles();
  const [units, setUnits] = useState([]);
  const [results, setResults] = useState([]);
  const [open, setOpen] = useState(false);
  const [dataAbilities, setDataAbilities] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setOpen(true);
      const { data } = await axios.get(datacronApi(props.type));
      let convertData = convertCsvToJson(data, ',');
      setResults(convertData)
      setOpen(false);
    };

    fetchData();

    axios.get('https://gasapi.info/api/countermanagement/Definition/units', axiosConfig)
      .then(response => {
        setUnits(response.data.unitDefinitions)
      })
      .catch(error => {
        setOpen(false);
        console.log(error);
      });

    axios.get('https://gasapi.info/api/countermanagement/Definition/datacronabilities', axiosConfig)
      .then(response => {
        const abilities = response.data.datacronAbilities;
        setDataAbilities(abilities)
      })
      .catch(error => {
        setOpen(false);
        console.log(error);
      });

  }, [props.type]);

  return (
    <Box>
      {dataAbilities.length > 0 && units.length > 0 && results.length > 0 && results.map((c, i) =>
        <Box className='gas-dc-guide' key={i} style={{ backgroundColor: `#${c.ColorCode}23`}}>
          <Box className='gas-dc-guide__team'>
            <Toon
              src={getUnitByCode(units, c.LeaderDB)?.imageReference} omicron={c.OmicronDB === 'Y'}
              attack={c.PositionDB === 'Offense' || c.PositionDB === 'Both'}
              defense={c.PositionDB === 'Defense' || c.PositionDB === 'Both'}
            />
            {c.Unit2DB && <Toon src={getUnitByCode(units, c.Unit2DB)?.imageReference} />}
            {c.Unit3DB && <Toon src={getUnitByCode(units, c.Unit3DB)?.imageReference} />}
            {c.Unit4DB && <Toon src={getUnitByCode(units, c.Unit4DB)?.imageReference} />}
            {c.Unit5DB && <Toon src={getUnitByCode(units, c.Unit5DB)?.imageReference} />}
          </Box>
          <Box className='gas-dc-guide__stats'>
            {c.Stat1DB && <DcStat value={c.Stat1DB} color={c.S1CC} />}
            {c.Stat2DB && <DcStat value={c.Stat2DB} color={c.S2CC} />}
            {c.Stat3DB && <DcStat value={c.Stat3DB} color={c.S3CC} />}
            {c.Stat4DB && <DcStat value={c.Stat4DB} color={c.S4CC} />}
            {c.Stat5DB && <DcStat value={c.Stat5DB} color={c.S5CC} />}

            {c.NotesDB &&
              <Box className='gas-dc-guide__notes'>
                <Tooltip title={c.NotesDB} placement="top">
                  <Button><SpeakerNotesIcon /></Button>
                </Tooltip>
              </Box>
            }
          </Box>
          <Box className='gas-dc-guide__datacron'>
            {c['Level3DB'] && <DcAvatar data={getUnitByCode(dataAbilities, c['Level3DB'])} />}
            {c['Level6DB'] && <DcAvatar data={getUnitByCode(dataAbilities, c['Level6DB'])} />}
            {c['Level9DB'] && <DcAvatar data={getUnitByCode(dataAbilities, c['Level9DB'])} />}
          </Box>
        </Box>
      )}
      <Backdrop open={open} className={classes.backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
}

export default Datacrons;