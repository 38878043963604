import characters from '../data/characters';
import ships from '../data/ships';

export const getCharacter = (c, type) => {
    let list = characters;
    if(type === 'fleet') { list = ships };
    const character = list.find(d => d.base_id === c);
    return character;
};

export const getPlayerUnit = (data, unit) => {
    const character = data.find(d => d.data.base_id === unit);
    return character.data;
};

export const getUnitByName = (data, unit) => {
    const character = data.find(d => d.name === unit);
    return character;
};

export const getUnitByCode = (data, unit) => {
    const character = data.find(d => d.code === unit);
    return character;
};

export const getUnitMods = (data, unit) => {
    const mods = data.filter(d => d.character === unit);
    const sorted = mods.sort((a, b) => parseFloat(a.slot) - parseFloat(b.slot));
    return sorted;
};

export const getSquad = (data, leader, type) => {
    let units = [];
    const counter = type === 'def' ? data.filter(d => d.DefLeader === leader) : data.filter(d => d.AtkLeader === leader);
    counter.forEach(e => {
        type === 'def' ? units.push(e.DefUnit2, e.DefUnit3, e.DefUnit4, e.DefUnit5, e.DefUnit6, e.DefUnit7) : units.push(e.AtkUnit2, e.AtkUnit3, e.AtkUnit4, e.AtkUnit5, e.AtkUnit6, e.AtkUnit7);
    });
    let removeEmpties = units.filter(empty => empty !== '');
    return removeEmpties;
};

export const filterCounters = (arr, target) => {
    return target.every(v => arr.includes(v));
};

export const getUnitDefense = (value, leader, modifiers) => {
    const leadMod = leader && leader.defense ? leader.defense / 100 : 0;
    const otherMod = modifiers && modifiers.length > 0 ? modifiers.reduce((x, y) => x + y.value, 0) / 100 : 0;
    const totalMod = leadMod + otherMod;
    const negCheck = Math.sign(totalMod);
    const adjustment = value * (Math.abs(totalMod));
    const calcValue = negCheck === -1 ? value - adjustment : value + adjustment;
    const percent = (calcValue/(calcValue+637.5)*100).toFixed(2);
    const effective = calcValue/(calcValue+637.5);
    return { percent: percent, effective: effective };
};