import React, { useState } from 'react';

import Box from '@material-ui/core/Box';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  label: {
    fontSize: '.875rem',
  },
});

const leaderList = [
  { label: 'Other', value: 'OTHER', defense: 0 },
  { label: 'Bossk (+100%)', value: 'BOSSK', defense: 100 },
  { label: 'CLS (+50%)', value: 'COMMANDERLUKESKYWALKER', defense: 50 },
  { label: 'STH (+40%)', value: 'STORMTROOPERHAN', defense: 40 }
];

const ChangeLeader = (props) => {
  const classes = useStyles();
  const [value, setValue] = useState('0');

  const onChange = (e) => {
    let value = e.target.value;
    setValue(value);
    let selected = leaderList.find((f => f.value === value));
    props.onChange && props.onChange(selected);
  }

  return (
    <Box pt={1}>
      <FormControl component="fieldset">
        <FormLabel component="legend">Leader Defense Bonus</FormLabel>
        <RadioGroup value={value} onChange={onChange} className="gas-form-control gas-form-control--small">
          {leaderList.map((l, i) =>
            <FormControlLabel
              value={l.value} control={<Radio color="primary" />} label={l.label}
              classes={{ label: classes.label }} key={i}
            />
          )}
        </RadioGroup>
      </FormControl>
    </Box>
  );
}

export default ChangeLeader;