import React from 'react';
import { getUnitDefense } from '../../../utils';

const DamageStats = (props) => {
  const { base, mods, leader, modifiers } = props;
  const health = base[1] + mods[1];
  const prot = base[28] + mods[28];
  const physDmg = getUnitDefense(base[8] + mods[8], leader, modifiers);
  const specDmg = getUnitDefense(base[9] + mods[9], leader, modifiers);
  
  return (
    <div className="gas-dmg-stats">
      <div className="gas-dmg-stats__item">
        <div className="gas-dmg-stats__label">Health + Protection</div>
        <div className="gas-dmg-stats__value">{health + prot}</div>
      </div>
      <div className="gas-dmg-stats__item">
        <div className="gas-dmg-stats__label">Armor</div>
        <div className="gas-dmg-stats__value">{physDmg.percent}%</div>
      </div>
      <div className="gas-dmg-stats__item">
        <div className="gas-dmg-stats__label">Resistance</div>
        <div className="gas-dmg-stats__value">{specDmg.percent}%</div>
      </div>
      <div className="gas-dmg-stats__item">
        <div className="gas-dmg-stats__label">Effective HP vs Physical</div>
        <div className="gas-dmg-stats__value">{((health + prot)/(1-(physDmg.effective))).toFixed(0)}</div>
      </div>
      <div className="gas-dmg-stats__item">
        <div className="gas-dmg-stats__label">Effective HP vs Special</div>
        <div className="gas-dmg-stats__value">{((health + prot)/(1-(specDmg.effective))).toFixed(0)}</div>
      </div>
    </div>
  );
}

export default DamageStats;